import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  },
  {
    path: '/video-gallery',
    name: 'VideoGallery',
    component: () => import(/* webpackChunkName: "video_gallery" */ '../views/VideoGallery.vue')
  },
  {
    path: '/organizer',
    name: 'Organizer',
    component: () => import(/* webpackChunkName: "organizer" */ '../views/Organizer.vue')
  },
  {
    path: '/tech-fair',
    name: 'TechFair',
    component: () => import(/* webpackChunkName: "tech_fair" */ '../views/TechFair.vue')
  },
  {
    path: '/central-committee',
    name: 'CentralCommittee',
    component: () => import(/* webpackChunkName: "centralCommittee" */ '../views/CentralCommittee.vue')
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "syllabus" */ '../views/Schedule.vue')
  },
  {
    path: '/syllabus',
    name: 'Syllabus',
    component: () => import(/* webpackChunkName: "syllabus" */ '../views/Syllabus.vue')
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/Resources.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    // component: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue')
    component: () => import(/* webpackChunkName: "registration" */ '../views/404.vue')
  },
  {
    path: '/registration-rules',
    name: 'RegistrationRules',
    component: () => import(/* webpackChunkName: "registration_rules" */ '../views/RegistrationRules.vue')
  },
  {
    path: '/participant-list/:id/:categoryId',
    name: 'ParticipantList',
    component: () => import(/* webpackChunkName: "participant_list" */ '../views/ParticipantList.vue')
  },
  {
    path: '/participant-profile',
    name: 'ParticipantProfile',
    component: () => import(/* webpackChunkName: "participant_profile" */ '../views/ParticipantProfile.vue')
  },
  {
    path: '/edit-participant-info',
    name: 'EditParticipantInfo',
    component: () => import(/* webpackChunkName: "edit_participant_info" */ '../views/EditParticipantInfo.vue')
  },
  {
    path: '/admit-download',
    name: 'AdmitDownload',
    component: () => import(/* webpackChunkName: "start_exam" */ '../views/AdmitDownload.vue')
  },
  {
    path: '/admit-download-new',
    name: 'AdmitDownloadNew',
    component: () => import(/* webpackChunkName: "start_exam" */ '../views/AdmitDownloadNew.vue')
  },
  {
    path: '/start-exam',
    name: 'StartExam',
    component: () => import(/* webpackChunkName: "start_exam" */ '../views/StartExam.vue')
  },
  {
    path: '/results',
    name: 'Results',
    component: () => import(/* webpackChunkName: "results" */ '../views/Results.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "notice" */ '../views/Notice.vue')
  },
  {
    path: '/past-paper/:eventId?/:categoryId?',
    name: 'PastPaper',
    component: () => import(/* webpackChunkName: "past_paper" */ '../views/PastPaper.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/timer',
    name: 'Timer',
    component: () => import(/* webpackChunkName: "timer" */ '../views/VACTimer.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
