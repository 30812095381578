<template>
  <div id="app">
    <logo/>
    <top-menu :regStat="registrationLink" :key="Math.random()"/>
    <router-view :key="$route.fullPath"/>
    <partner/>
    <big-footer/>
    <Footer/>
  </div>
</template>

<script>
  import TopMenu from "./components/header/menu/TopMenu";
  import Logo from "./components/header/Logo";
  import Partner from "./components/Partner";
  import BigFooter from "./components/footer/BigFooter";
  import Footer from "./components/footer/Footer";

  export default {
    name: 'App',
    head:{
      link:[
        {
          rel: "icon",
          href: require("./assets/site_images/favicon.png")
        }
      ]
    },
    components: {
      'top-menu':TopMenu,
      'logo':Logo,
      Footer,
      'big-footer':BigFooter,
      'partner':Partner,
    },
    data(){
      return {
        registrationLink:null,
      }
    },
    beforeCreate() {
      this.$http.get("registration-status").then(function (response) {

        if (JSON.parse(localStorage.getItem('participant')) !== null){

          if (response.body===true){
            this.registrationLink = true;
            sessionStorage.setItem('registrationStatus',true);
          }else {
            this.registrationLink = false;
            sessionStorage.setItem('registrationStatus',false);
          }

        }else {
          this.registrationLink = response.body;
          sessionStorage.setItem('registrationStatus',response.body);
        }
      });
    },
    created(){
      this.$http.get('index').then(response => {
        this.$store.state.notices.push(response.body['notices']); //Used
        this.$store.state.noticeCount = (response.body['noticeCount']); //Used
        this.$store.state.divisions.push(response.body['divisions']); //Used
        this.$store.state.syllabus.push(response.body['syllabuses']); //Used
        this.$store.state.pastPapers.push(response.body['pastPapers']); //Used
        this.$store.state.pastEvents.push(response.body['pastEvents']); //Used
        this.$store.state.info.push(response.body['info']);
        this.$store.state.siteTitle = response.body['title']; //Used
        this.$store.state.fbLikeBox = response.body['fb_like_box']; //Used
        this.$store.state.visitorCounter = response.body['visitor_counter'];  //Used
        this.$store.state.registrationRules.push(response.body['registration_rules']);  // Used
        document.title = this.$store.getters.getSiteTitle;
      });
    },
    beforeUpdate(){

    },
    updated() {
      if (JSON.parse(localStorage.getItem('participant')) !== null){
        this.registrationLink = false;
      }else if (JSON.parse(sessionStorage.getItem('registrationStatus'))===true) {
        this.registrationLink = true;
      }
    },
    methods:{

    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
